import { useCallback, useMemo, useState } from "react";
import { addHours, isAfter } from "date-fns";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useSystemConfig from "../../../hooks/useSystemConfig";
import { AppointmentCancelDialogProps } from "../AppointmentCancelDialog";
import useTranslations from "./useTranslations";
import { Appointment } from "./types";
import {
  convertDatabaseDateToTimezone,
  getNowUTC,
} from "../../../common/dateHelpers";

const useAppointmentCancelDialog = (
  appointment: Appointment | null,
): {
  handleOpen: () => void;
  props: AppointmentCancelDialogProps;
} => {
  const { cancelTranslations } = useTranslations();
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const cancelAppointment = useFirebaseAppFunction("cancelAppointment");

  const { dataState: systemConfig } = useSystemConfig();

  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage(null);
  };

  const handleSubmit = useCallback(async () => {
    if (!appointment) return;
    setIsLoading(true);
    try {
      const { data } = await runAsyncFunction(cancelAppointment, {
        appointmentId: appointment.id,
      });

      if (data.status === "error") {
        const { code } = data.error;

        switch (code) {
          case "APPOINTMENT_IN_PAST": {
            throw new Error(cancelTranslations.cancelingAppointmentInPastError);
          }
          case "STATUS_NOT_SUPPORTED": {
            throw new Error(cancelTranslations.statusNotSupportedToCancelError);
          }
          default: {
            throw new Error(cancelTranslations.defaultError);
          }
        }
      }

      handleClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [
    appointment,
    runAsyncFunction,
    cancelAppointment,
    cancelTranslations.cancelingAppointmentInPastError,
    cancelTranslations.statusNotSupportedToCancelError,
    cancelTranslations.defaultError,
  ]);

  const isRefundAvailable = useMemo(() => {
    if (!appointment) return false;

    const refundAppointmentMinHours =
      (appointment.center.center_configs?.[0] || {})
        .refund_canceling_appointment_min_hours ||
      systemConfig.data?.refund_canceling_appointment_min_hours;

    const { dateUTC: startedAtUtc } = convertDatabaseDateToTimezone(
      appointment.center_time_slot.started_at,
    );

    const nowUtc = getNowUTC();

    const isAvailable =
      typeof refundAppointmentMinHours === "number" &&
      isAfter(startedAtUtc, addHours(nowUtc, refundAppointmentMinHours));

    return isAvailable;
  }, [appointment, systemConfig.data?.refund_canceling_appointment_min_hours]);

  return {
    handleOpen,
    props: {
      isOpen,
      handleClose,
      handleSubmit,
      isLoading,
      errorMessage,
      isRefundAvailable,
    },
  };
};

export default useAppointmentCancelDialog;
